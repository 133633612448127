import React, { useCallback, FC, useState } from 'react';
import { Button, CheckBox, Spinner } from '@components/Atoms';
import { fromMarkdown, renderParagraphs } from '@lib/utils';
import { ButtonType } from '@components/Atoms/Button/types';
import { StyledErrorBar } from '@components/Atoms/Form';
import styled from 'styled-components';
import { Dropdown } from '@components/Molecules';
import { init, sendForm } from '@emailjs/browser';
import StyledCheckoutFormRow from '@components/Organisms/OrderForms/styled/StyledCheckoutFormRow';
import StyledCheckoutCheckbox from '@components/Organisms/OrderForms/styled/StyledCheckoutCheckbox';
import { CheckoutFormRow } from '@components/Organisms/OrderForms/CheckoutFormRow';
import StyledButtonRow from '@components/Organisms/OrderForms/styled/StyledButtonRow';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { useRouter } from 'next/router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaCallback from '@lib/utils/recaptchaCallback';
import { customerProspectServer } from '@controllers/ProductController/ProductController';

interface FieldI {
  key: string;
  label?: string;
  type?: string;
  required?: boolean;
  value?: string | Array<string>;
  options?: Array<{
    label: string;
    alias: string;
    value: string;
    checked: boolean;
  }>;
  cols?: number;
  placeholder?: string;
  preText?: JSX.Element;
}

const STYLED = {
  checkboxes: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.25rem;
    margin-top: 1rem;
  `,
  loader: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  `,
  dropdown: styled.div`
    margin-top: 1rem;
  `,
};

const StyledForm = styled.form`
  [class*='StyledDropdown'] {
    background-color: white;
  }

  [class*='StyledCheckoutFormRow'] {
    font-size: 0.9rem;
  }
`;

const StyledFormGridItem = styled.div<{ cols: number | undefined }>`
  grid-column: span ${({ cols }) => cols};

  *:not(.booleanInput) input,
  *:not(.booleanInput) textarea {
    background-color: white;
  }
  &:not(.booleanInput) textarea {
    height: 12rem;
    &::placeholder {
      font-size: 0.75rem;
      color: var(--colors-grey-30);
    }
  }
  * {
  }
`;

const contactTitles = [
  { value: 'DR', label: 'Dr.' },
  { value: 'MISS', label: 'Miss.' },
  { value: 'MR', label: 'Mr.' },
  { value: 'MRS', label: 'Mrs.' },
  { value: 'MS', label: 'Ms.' },
  { value: 'SIR', label: 'Sir' },
];

init('user_kpWsSBhCezkaPPptoqvqP');

export const Form: FC = () => {
  const router = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = useState('');

  const fields: Array<FieldI> = [
    { key: 'contact_name', label: 'First Name', required: true, value: '', cols: 1 },
    { key: 'contact_surname', label: 'Last Name', required: true, value: '', cols: 1 },
    { key: 'email', label: 'Email Address', required: true, value: '', cols: 1 },
    { key: 'phone', label: 'Phone Number', required: true, value: '', cols: 1 },
    {
      key: 'accept_terms',
      type: 'checkbox',
      required: true,
      value: '',
    },
    {
      key: 'accept_marketing',
      type: 'checkbox',
      value: '',
    },
  ];

  const defaultFormData = fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.key]: field.value,
    };
  }, {});

  const [formData, setFormData] = useState<any>(defaultFormData);

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formDataValues: Array<{
    [key: string]: string;
  }> = Object.values(formData);

  const validators = [
    {
      keys: [],
      isValid: formDataValues.every((field) => field),
      message: 'Field is required',
    },
    {
      keys: ['contact_title'],
      isValid:
        formData.contact_title &&
        ['DR', 'MISS', 'MRS', 'MR', 'MRS', 'MS', 'SIR'].indexOf(
          formData?.contact_title?.value || '',
        ) > -1,
      message: 'Please enter a valid title',
    },
    {
      keys: ['email'],
      isValid: formData.email?.match(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/),
      message: 'Email is invalid',
    },
    {
      keys: ['phone'],
      isValid: formData.phone?.match(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      ),
      message: 'Phone is invalid',
    },
    {
      keys: ['accept_terms'],
      isValid: formData.accept_terms,
      message: 'You must agree to the terms and conditions to proceed',
    },
  ];

  const { postcodeItem } = usePostcodeContext();
  const isValid = validators.every((validator) => validator.isValid) && postcodeItem;
  const errorOnSubmit = hasSubmitted && validators.some((validator) => !validator.isValid);

  const updateFormData = (key: string, value: any) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const submitForm = useCallback(
    (event) => {
      event?.preventDefault();

      try {
        if (!executeRecaptcha) {
          setError('Recaptcha has failed, please try again.');
          console.error('Execute recaptcha not yet available');
          return;
        }

        executeRecaptcha('checkoutFormSubmit').then((gReCaptchaToken) =>
          recaptchaCallback({
            token: gReCaptchaToken,
            onError: () => setError('Recaptcha has failed, please try again.'),
            onSuccess: async () => {
              if (!formData || !postcodeItem) {
                setError('formData or postcodeItem is undefined');
                console.error('formData or postcodeItem is undefined');
                return;
              }

              // Args for customerProspectServer
              const { email, phone } = formData;
              const { uprn } = postcodeItem;
              const args = { email, phone, uprn };

              // Hit the API
              const service = process.env.NEXT_PUBLIC_EMAIL_SERVICE || 'service_2cgv65g';
              //   const user = process.env.NEXT_PUBLIC_EMAIL_SERVICE_USER;

              sendForm(service, 'template_i3eydvk', event.target);

              setLoading(true);
              router.push(
                `/bundles/gigaplus/success?firstName=${encodeURIComponent(formData.contact_name)}`,
              );

              setTimeout(() => {
                setLoading(false);
                setHasSubmitted(true);
              }, 10000);
            },
          }),
        );
      } catch (error) {
        console.error('Recaptcha not verified');
        setError('Recaptcha has failed, please try again.');
      }
    },
    [formData],
  );

  return (
    <StyledForm onSubmit={submitForm} method="post">
      {error ? <StyledErrorBar>{error}</StyledErrorBar> : null}
      {errorOnSubmit ? (
        <StyledErrorBar>
          There was an error submitting your details. Please try again.
        </StyledErrorBar>
      ) : null}
      {loading ? (
        <STYLED.loader>
          <Spinner />
        </STYLED.loader>
      ) : null}
      <StyledCheckoutFormRow>
        <Dropdown
          items={contactTitles}
          id="contact_title"
          placeholderLabel="Title"
          defaultSelectedItem={formData.contact_title}
          onChange={(state: { value: string }) =>
            state?.value ? updateFormData('contact_title', state) : null
          }
          required={true}
        />
      </StyledCheckoutFormRow>
      {fields
        .filter(({ type }) => type !== 'checkbox')
        .map(({ key, label, type, required, options, cols, placeholder, preText }: FieldI) => (
          <StyledFormGridItem cols={cols} key={key}>
            <CheckoutFormRow
              key={key}
              name={key}
              label={label}
              formData={formData}
              validators={validators}
              onChange={updateFormData}
              type={type}
              required={required}
              options={options}
              placeholder={placeholder}
              preText={preText}
            />
          </StyledFormGridItem>
        ))}

      <STYLED.checkboxes>
        <StyledCheckoutCheckbox>
          <CheckBox
            value="Checkbox"
            label={renderParagraphs({
              body: '<span>I agree to the [Terms & Conditions](/legal)</span>',
              hasMarkdown: true,
            })}
            name="accept_terms"
            id="accept_terms"
            checked={formData.accept_terms}
            onClick={(value) => updateFormData('accept_terms', value)}
          />
        </StyledCheckoutCheckbox>
        <StyledCheckoutCheckbox>
          <CheckBox
            value={formData.accept_marketing}
            label={fromMarkdown({
              text: 'By checking this box, you’re providing your consent for Hey!B to use your information to contact you regarding  our plans to deploy our network in your area, and from time to time news and special promotions.You can unsubscribe at anytime by emailing us on [support@heyb.co.uk](support@heyb.co.uk) or by calling us on [0330 822 2878](tel:03308222878). Check out our Privacy Policy to find our more on how we look after your data.',
              isHeading: false,
            })}
            name="accept_marketing"
            id="accept_marketing"
            checked={formData.accept_marketing}
            onClick={() => updateFormData('accept_marketing', !formData.accept_marketing)}
          />
        </StyledCheckoutCheckbox>
      </STYLED.checkboxes>
      {error ? <StyledErrorBar>{error}</StyledErrorBar> : null}
      {errorOnSubmit ? (
        <StyledErrorBar>
          There was an error submitting your details. Please try again.
        </StyledErrorBar>
      ) : null}
      <input type="hidden" name="address" value={postcodeItem?.address} />
      <StyledButtonRow className="mt-10">
        <Button buttonType={ButtonType.PRIMARY} type="submit" disabled={!isValid}>
          Register your interest
        </Button>
      </StyledButtonRow>
      <div className="mt-8" id="recaptcha-container"></div>
    </StyledForm>
  );
};

import React from 'react';

import { Addons as Organism } from '@components/Organisms';

export const Addons = ({
  display,
  addonName,
  title,
  subtitle,
  bodyContent,
  trustpilot,
  addonProductIdOne,
  addonProductIdTwo,
  products,
  exclusion_list,
}) => {
  return (
    <Organism
      display={display}
      addonName={addonName}
      title={title}
      subtitle={subtitle}
      bodyContent={bodyContent}
      trustpilot={trustpilot}
      addonOne={addonProductIdOne}
      addonTwo={addonProductIdTwo}
      products={products}
      exclusion_list={exclusion_list}
    />
  );
};
